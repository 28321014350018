import { Component, Prop, Vue } from 'vue-property-decorator';
import template from './Edit.Template.vue';
import EditOrCreate from '../EditOrCreate.Template.vue';
import { Getter, Action } from 'vuex-class';
import { ReferentielStoreMethods, getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { ValeurReferentielle, TypeValeurReferentielle } from '@/shared/models';
import { TemplateOperation, ArreteReferentiel } from '@/models';

@Component({
    ...template,
    name: 'TemplateOperationsEdit',
    components: {
        EditOrCreate,
    },
})
export default class TemplateOperationsEdit extends Vue {
    public $refs!: { form: HTMLFormElement };

    @Prop({ default: '' }) public readonly templateOperationId!: string;

    @Getter(getterKeyReferentiel(TypeValeurReferentielle.Arrete))
    public arretes: ArreteReferentiel[];
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>;


    private loading: boolean = false;
    private templateOperation: TemplateOperation = new TemplateOperation();

    /**
     * Retourne le libellé de la version de l'arrêté.
     */
    public get libellerVersionArrete(): string | null {
        if (this.templateOperation && this.templateOperation.arreteId >= 0 && this.arretes && this.arretes.length >= 1) {
            const arrete = this.arretes.find((item) => item.id === this.templateOperation.arreteId);
            if (arrete != null && typeof arrete !== 'undefined') {
                return arrete.libelle;
            }
        }
        return null;
    }

    public mounted(): void {
        this.recupererTemplateOperation();
    }

    public recupererTemplateOperation() {
        this.loading = true;
        this.$http.get(`/templateOperations/${this.templateOperationId}`).then((result: any) => {
            this.templateOperation = result.data.data as TemplateOperation;
        }).finally(() => this.loading = false);
    }

}

